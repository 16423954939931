<template>
  <section>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="card-title ">
          Head-wise Ledger
        </p>
      </div>

      <!-- select & search input -->
      <div class="custom-search d-flex">
        <!-- serch for Student ID  -->
        <b-form-group
          class="pr-0 col-lg-3 col-md-3"
        >
          <v-select
            v-model="coaHeadId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="coaHeadOptions"
            :reduce="(val) => val.id"
            placeholder="Select COA Head"
            @input="changeCOA"
          />
        </b-form-group>

        <b-form-group
          class="pr-0 col-lg-2 col-md-2"
        >
          <flat-pickr
            v-model="fromDateTime"
            placeholder="From Date/Time"
            class="form-control"
            required
          />
          <!-- :config="{ enableTime: true,dateFormat: 'Y-m-d H:i',defaultDate: 'today'}" -->
        </b-form-group>

        <b-form-group
          class="pr-0 col-lg-2 col-md-2"
        >
          <flat-pickr
            v-model="toDateTime"
            placeholder="To Date/Time"
            class="form-control"
            required
          />
        </b-form-group>

        <b-form-group
          label-sr-only
          class="pr-0 col-lg-4 col-md-4"
        >
          <b-button
            type="submit"
            variant="outline-success"
            class="mr-1"
            @click.prevent="searchFunc"
          >
            Search
          </b-button>
          <b-button
            type="submit"
            variant="outline-danger"
            class="mr-1"
            @click.prevent="clearFunc"
          >
            Clear
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
            class="mr-1"
            @click.prevent="printFunc"
          >
            Print
          </b-button>
        </b-form-group>
      </div>
      <div
        v-if="glView"
        class="m-2"
      >
        <span>
          GL Name: <b>{{ glName }}</b> | GL Code: <b>{{ glCode }}</b>
        </span>
      </div>
      <div style="text-align:center">
        <b-spinner
          v-if="isLoading"
          variant="primary"
          label="Text Centered"
        />
      </div>

      <div v-if="isShow">
        <table
          class="table table-hover table-bordered"
        >
          <thead>
            <tr class="left-align">
              <th
                width="5px"
                class="left-align"
              >
                SL.
              </th>
              <th
                width="200px"
                class="left-align"
              >
                Date
              </th>
              <th
                width="500px"
                class="left-align"
              >
                Description
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Debit
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Credit
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Balance
              </th>
            </tr>
          </thead>
          <tbody v-if="isShow && rows.length > 0">
            <tr
              v-for="(item, index) in rows"
              :key="item.id"
              class="left-align"
            >
              <td class="left-align">
                {{ rows.length === index + 1 ? '' : index + 1 }}
              </td>
              <td class="left-align">
                {{ item.date }}
              </td>
              <td class="left-align">
                {{ rows.length === index + 1 ? 'Total' : item.description }}
              </td>
              <td class="right-align">
                <b>{{ item.debit }}</b>
              </td>
              <td class="right-align">
                <b>{{ item.credit }}</b>
              </td>
              <td class="right-align">
                <b>{{ item.balance }}</b>
                <!-- <h1 style="text-decoration: underline double;"><u>About Us</u></h1> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </b-card>
    <HeadwiseLedgerPdf
      v-if="isPrintDocument"
      ref="docRef"
      :document-data="documentData"
      :gl-info="glInfo"
      :date-range="dateRange"
    />
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  VBModal,
  BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'
import vSelect from 'vue-select'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import { showOnlyDateDBFormat } from '@/@service/utils/utils'
import HeadwiseLedgerPdf from '@/views/head-wise-ledger/components/HeadwiseLedgerPdf.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BButton,
    vSelect,
    flatPickr,
    BSpinner,
    HeadwiseLedgerPdf,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {

      pageLength: 10,
      dir: false,
      rows: [],
      tempRows: [],

      coaHeadId: null,
      coaHeadOptions: [],
      fromDateTime: '',
      toDateTime: '',
      isShow: false,
      isLoading: false,

      documentData: {},
      isPrintDocument: false,
      dateRange: {},
      glInfo: {},
      glName: null,
      glCode: null,
      glView: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getCoa()
  },
  methods: {
    changeCOA(val) {
      if (val === null) {
        this.glView = false
        this.rows = []
      } else {
        this.glName = this.coaHeadOptions.find(x => x.id === val).gl_name
        this.glCode = this.coaHeadOptions.find(x => x.id === val).gl_code
      }
    },
    getCoa() {
      this.$http
        .get(`${window.apiUrl}accounting/ledger-coa`)
        .then(res => {
          if (res.data.status) {
            this.coaHeadOptions = res.data.data
          } else {
            this.coaHeadOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    searchFunc() {
      this.getGridData()
    },

    getGridData() {
      this.isLoading = true
      this.rows = []
      this.isShow = true
      if (this.coaHeadId === null || this.coaHeadId === '') {
        FemsToastrService.error('Please Select a COA Head')
        this.isLoading = false
        this.isShow = false
        return
      }

      const from = this.fromDateTime === '' ? this.fromDateTime : showOnlyDateDBFormat(this.fromDateTime)
      const to = this.toDateTime === '' ? this.toDateTime : showOnlyDateDBFormat(this.toDateTime)

      if (Date(to) < Date(from)) {
        FemsToastrService.error('From Date cannot be greater than To Date')
        this.isLoading = false
        this.isShow = false
        return
      }

      const params = {
        fromDate: from,
        toDate: to,
      }

      this.$http
        .put(`${window.apiUrl}accounting/coa-ledger-report/${this.coaHeadId}`, params)
        .then(res => {
          if (res.data.status) {
            this.isShow = true
            this.glView = true
            this.isLoading = false
            this.rows = res.data.data
            this.documentData = res.data
            this.glInfo = {
              name: this.glName,
              code: this.glCode,
            }
            this.dateRange = {
              fromDate: this.fromDateTime === '' ? this.fromDateTime : showOnlyDateDBFormat(this.fromDateTime),
              toDate: this.toDateTime === '' ? this.toDateTime : showOnlyDateDBFormat(this.toDateTime),
            }
            if (!this.rows.length) {
              this.isShow = false
              this.glView = false
              this.isLoading = false
            }
          } else {
            this.rows = []
            this.isShow = false
            this.glView = false
            this.isLoading = false
          }
        })
        .catch(err => {
          this.rows = []
          this.isShow = false
          this.isLoading = false
          FemsToastrService.error(err?.message)
        })
    },

    clearFunc() {
      this.searchStudents = []
      this.idState = null

      this.pageLength = 10
      this.dir = false
      this.rows = []

      this.fromDateTime = ''
      this.toDateTime = ''
      this.isShow = false
      this.isLoading = false

      this.coaHeadId = null

      this.glView = false
      this.documentData = {}
      this.isPrintDocument = false
      this.dateRange = {}
    },

    printFunc() {
      if (!this.rows.length) {
        FemsToastrService.error('No Ledger data to be print')
        return
      }
      this.isPrintDocument = true
      this.$nextTick(() => {
        this.$refs.docRef.printDocument()
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.left-align{
  padding-left: 10px;
}
.right-align{
  text-align: right;
  padding-right: 20px
}
.table-hover tbody tr {
  cursor : text !important;
}
</style>
