<template>

  <section
    v-if="documentData && glInfo && documentData.data && documentData.data.length"
    class="invoice-preview-wrapper"
  >
    <div id="exportArea">
      <!--Header-->
      <div
        class="page-header"
        style="text-align: center"
      >
        <div
          class="logo-wrapper"
          :style="{ marginBottom: '0px' }"
        >
          <b-img
            fluid
            :src="cmsData.favicon"
            alt="Fems"
            :style="{ height: '40px'}"
            class="float-left"
          />
        </div>
        <div>
          <h1>{{ cmsData.name }}</h1>
        </div>
        <div>
          <h2>{{ cmsData.address }}</h2>
        </div>
        <div>
          <h2>Phone No. : {{ cmsData.phone_no }}</h2>
        </div>
        <!-- <div>
          <h2>Mobile No. : {{ cmsData.mobile_no }}</h2>
        </div> -->
        <div
          class="mt-1"
          style="font-size: 22px"
        >
          <h2>Head-wise Ledger</h2>
        </div>
        <div
          v-if="glInfo.name && glInfo.code"
          class="mt-1"
          style="font-size: 25px"
        >
          <span>
            GL Name: <b>{{ glInfo.name }}</b> | GL Code: <b>{{ glInfo.code }}</b>
          </span>
        </div>
        <div
          v-if="dateRange.fromDate&& dateRange.toDate"
          class="mt-1 mb-2"
          style="font-size: 22px "
        >
          <b>From:</b> {{ dateRange.fromDate }} - <b>To:</b> {{ dateRange.toDate }}
        </div>
      </div>
      <!--Footer-->
      <div
        id="pageFooter"
        class="page-footer"
      >
        <div class="d-flex justify-content-between">
          <div>
            <b>Print Date</b>
            <span class="ml-text1">: {{ showLocalDateTime(currentDateTime) }} </span>
          </div>
          <div :style="{paddingRight:'90px'}">
            <b>Powered BY</b>
            <span class="ml-text3">: {{ cmsData.powered_by || "fitl.com.bd" }} </span>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <!--place holder for the fixed-position header-->
              <div class="page-header-space" />
            </td>
          </tr>
        </thead>
        <table
          id="printTable"
          class="page"
        >
          <thead>
            <tr class="left-align">
              <th
                width="5px"
                class="left-align"
              >
                SL.
              </th>
              <th
                width="200px"
                class="left-align"
              >
                Date
              </th>
              <th
                width="500px"
                class="left-align"
              >
                Description
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Debit
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Credit
              </th>
              <th
                width="100px"
                class="right-align"
              >
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in documentData.data"
              :key="item.id"
              class="left-align"
            >
              <td class="left-align">
                {{ documentData.data.length === index + 1 ? '' : index + 1 }}
              </td>
              <td class="left-align">
                {{ item.date }}
              </td>
              <td class="left-align">
                {{ documentData.data.length === index + 1 ? 'Total Balance' : item.description }}
              </td>
              <td class="right-align">
                <b>{{ item.debit }}</b>
              </td>
              <td class="right-align">
                <b>{{ item.credit }}</b>
              </td>
              <td class="right-align">
                <b>{{ item.balance }}</b>
                <!-- <h1 style="text-decoration: underline double;"><u>About Us</u></h1> -->
              </td>
            </tr>
          </tbody>
        </table>
        <tfoot>
          <tr>
            <td>
              <!--place holder for the fixed-position footer-->
              <div class="page-footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
</template>
<script>
import {
  VBToggle,
  BImg,
} from 'bootstrap-vue'
// import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import PDFGenerator from '@/@service/utils/PDFGenerator'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import { showLocalDateTime } from '@/@service/utils/utils'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    showLocalDateTime,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    documentData: Object,
    dateRange: Object,
    glInfo: Object,
  },
  data() {
    return {
      cmsData: JSON.parse(LocalStorageService.get('pageData')) || '',
      totalAmount: 0,
      invoiceCopies: ['Bank', 'School', 'Student'],
      currentDateTime: new Date(),
    }
  },

  created() {
    if (!this.cmsData) {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    // PDF generator using node package jspdf
    generatePdf() {
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      const paperOrientation = 'l'
      const paperSize = 'A4'
      PDFGenerator.generatePDF(content, paperOrientation, paperSize)
      content.classList.add('d-none')
    },
    // Print invoice using window.print() method javascript default
    printDocument() {
      const pdfTitle = document.title
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      document.title = 'Headwise Ledger'
      window.print()
      document.title = pdfTitle
      content.classList.add('d-none')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  @page {
    //size: landscape;
    margin: 8mm 5mm 8mm 5mm;

  }
  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
  }
  .page-header, .page-header-space {
    align-items: center;
    min-height: 220px;
  }

  .page-footer, .page-footer-space {
    height: 50px;
  }
  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .page {
    page-break-after: always;
  }
  thead {display: table-header-group;}
  //tfoot {display: table-footer-group;}
  #pageFooter {
    display: table-footer-group;
  }

  /*#pageFooter:after {
    counter-increment: page;
    content: counter(page);
  }*/
  button {display: none;}
  /* Images */
  div[class="row"] {
    outline: 1px dotted rgba(0, 77, 0, 0.25);
  }

  div[class^="col-"] {
    background-color: rgba(0, 25, 33, 0.2);
    outline: 1px dotted rgba(0, 0, 0, 0.3);
  }
  // Global Styles
  body {
    background-color: transparent !important;
    //margin: 25mm 25mm 25mm 25mm;
  }
  .page-break {
    page-break-after: always;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .card{
    display: none;
  }
  footer.footer {
    display: none;
  }
  .btn-scroll-to-top {
    display: none;
  }
  .custom-search{
    display: none !important;
  }
  .vueTable{
    display: none !important;
  }
  #printTable {
    border: 1px solid black;
  }
  #printTable td,
  th {
    border: 1px solid black;
  }
  #printTable {
    width: 100%;
    border-collapse: collapse;
  }
  .left-align{
    padding-left: 10px;
  }
  .right-align{
    text-align: right;
    padding-right: 20px
  }
}
</style>
